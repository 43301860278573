/*
 * @Author: 董方旭
 * @LastEditors: 董方旭
 */
/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `src/translations`)
 */

import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { i18nConf } from 'configs/base.conf';
import RewriteIntl from './RewriteIntl';
import { selectLocale } from './selectors';

interface ILanguageProviderProps {
  locale: string;
  messages: any;
  children: any;
}

export function LanguageProvider(props: ILanguageProviderProps) {
  let antdLanguage = zhCN;
  dayjs.locale('zh-cn');
  if (props.locale === 'en-US') {
    antdLanguage = enUS;
    dayjs.locale('en');
  }

  return (
    <IntlProvider
      locale={props.locale}
      key={props.locale}
      messages={props.messages[props.locale]}
      defaultLocale={i18nConf.defaultLocale}
    >
      <RewriteIntl>
        <ConfigProvider locale={antdLanguage}>{React.Children.only(props.children)}</ConfigProvider>
      </RewriteIntl>
    </IntlProvider>
  );
}

const mapStateToProps = createSelector(selectLocale, locale => ({
  locale,
}));

export default connect(mapStateToProps)(LanguageProvider);
